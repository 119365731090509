(function ($) {
    document.addEventListener('DOMContentLoaded', function() {
        
        // Banner carousel functionality
        const covidScroll = document.querySelector('.covid-banner-scroll');
        const covidItems = document.querySelectorAll('.covid-banner');
        const covidPrevBtn = document.querySelector('.covid-prev');
        const covidNextBtn = document.querySelector('.covid-next');
        
        // Only proceed if we have the necessary elements
        if (!covidScroll || !covidItems.length) {
            return;
        }

        let covidCurrentIndex = 0;
        const covidTotalItems = covidItems.length;

        // Hide navigation if only one banner
        if (covidTotalItems <= 1) {
            if (covidPrevBtn) covidPrevBtn.style.display = 'none';
            if (covidNextBtn) covidNextBtn.style.display = 'none';
        } else {
            // Setup carousel functionality only if multiple banners
            function updateCovidBanner(index) {
                covidScroll.style.transform = `translateX(-${index * 100}%)`;
                covidCurrentIndex = index;
            }
            
            if (covidPrevBtn) {
                covidPrevBtn.addEventListener('click', function() {
                    const prevIndex = (covidCurrentIndex - 1 + covidTotalItems) % covidTotalItems;
                    updateCovidBanner(prevIndex);
                });
            }
            
            if (covidNextBtn) {
                covidNextBtn.addEventListener('click', function() {
                    const nextIndex = (covidCurrentIndex + 1) % covidTotalItems;
                    updateCovidBanner(nextIndex);
                });
            }

            // Auto scroll only for multiple banners
            const autoScroll = setInterval(function() {
                const nextIndex = (covidCurrentIndex + 1) % covidTotalItems;
                updateCovidBanner(nextIndex);
            }, 8000);
        }

        // Coupon modal functionality
        $(document).on('click', 'a[data-reveal-id="coupon-modal"]', function(e) {
            e.preventDefault();
            const banner = $(this).closest('.covid-banner');
            const modalContent = [];
            const code = banner.data('code');
            const endDate = new Date(banner.data('end-date')).toLocaleDateString();
            const minOrder = banner.data('minimum-order');
            const uses = banner.data('uses');
            const deliveryStart = new Date(banner.data('delivery-start')).toLocaleDateString();
            const deliveryEnd = new Date(banner.data('delivery-end')).toLocaleDateString();
            // Build modal content
            modalContent.push(`*Cannot be combined with other offers. Enter code <strong>${code}</strong> at the payment step in checkout to apply the discount and enjoy your savings.`);
            modalContent.push(`Coupon valid until ${endDate}`);

            const requirements = [];
            if (minOrder > 0) {
                requirements.push(`  •  Order total must meet minimum amount: <strong>$${minOrder}</strong>`);
            }
            if (uses > 0) {
                requirements.push(`  •  Available for the first <strong>${uses}</strong> customers`);
            }
            if (deliveryStart && deliveryEnd) {
                if (deliveryStart === deliveryEnd) {
                    requirements.push(`  •  Exclusive for delivery/pick up date: <strong>${deliveryStart}</strong>`);
                } else {
                    requirements.push(`  •  Exclusive for deliveries/pickups between: <strong>${deliveryStart}</strong> and <strong>${deliveryEnd}</strong>`);
                }
            }
            // Add the requirements to the modal content only if there are any
            if (requirements.length > 0) {
                modalContent.push(`<strong>Eligibility Requirements:</strong>\n${requirements.join("\n")}`);
            }

            $('.banner-modal-content').html(modalContent.join("\n\n"));
        });
    });
})(jQuery);